var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import * as React from "react";
var SvgMenu = function SvgMenu(props) {
  return React.createElement("svg", __assign({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 359 359",
    style: {
      enableBackground: "new 0 0 359 359"
    },
    xmlSpace: "preserve"
  }, props), React.createElement("path", {
    d: "M96 0H13C7.5 0 3 4.5 3 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10zm-9 84H23V20h64v64zM221 0h-83c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10zm-9 84h-64V20h64v64zM96 126H13c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10v-83c0-5.5-4.5-10-10-10zm-9 83H23v-63h64v63zM221 126h-83c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10v-83c0-5.5-4.5-10-10-10zm-9 83h-64v-63h64v63zM346 0h-83c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10zm-9 84h-64V20h64v64zM346 126h-83c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10v-83c0-5.5-4.5-10-10-10zm-9 83h-64v-63h64v63zM96 256H13c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10v-83c0-5.5-4.5-10-10-10zm-9 84H23v-64h64v64zM221 256h-83c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10v-83c0-5.5-4.5-10-10-10zm-9 84h-64v-64h64v64zM346 256h-83c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10v-83c0-5.5-4.5-10-10-10zm-9 84h-64v-64h64v64z"
  }));
};
export default SvgMenu;