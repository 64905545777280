import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import '../locales/i18n';
import { baseUrl } from "../lib/location";
export default function IdentityProviders(_a) {
  var providers = _a.providers,
    authBaseUrl = _a.authBaseUrl,
    redirectUri = _a.redirectUri,
    authClientId = _a.authClientId,
    _b = _a.displayIdPTitle,
    displayIdPTitle = _b === void 0 ? true : _b;
  var t = useTranslation().t;
  var redirectUriGenerator = useMemo(function () {
    return typeof redirectUri === 'function' ? redirectUri : function (provider) {
      return "".concat(redirectUri || "".concat(baseUrl, "/auth"), "/").concat(provider.name);
    };
  }, [redirectUri, baseUrl]);
  return React.createElement("div", {
    className: 'identity-providers',
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap'
    }
  }, providers.map(function (provider) {
    var redirectUri = redirectUriGenerator(provider);
    var authorizeUrl = "".concat(authBaseUrl, "/").concat(provider.type, "/").concat(provider.name, "/authorize?redirect_uri=").concat(encodeURIComponent(redirectUri), "&client_id=").concat(authClientId);
    return React.createElement("div", {
      key: provider.name,
      className: "identity-provider",
      style: {
        margin: 10,
        textAlign: 'center'
      }
    }, React.createElement("a", {
      className: 'btn btn-light',
      href: authorizeUrl,
      title: provider.title
    }, provider.logoUrl && React.createElement("div", {
      style: {
        marginBottom: displayIdPTitle ? 5 : 0
      }
    }, React.createElement("img", {
      src: provider.logoUrl,
      alt: provider.title,
      style: {
        maxWidth: 50,
        maxHeight: 50
      }
    })), (displayIdPTitle || !provider.logoUrl) && React.createElement("div", null, provider.title)));
  }));
}