var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import axios from "axios";
var accessTokenStorageKey = 'accessToken';
var usernameStorageKey = 'username';
export var authenticationEventType = 'authentication';
export var loginEventType = 'login';
export var logoutEventType = 'logout';
var OAuthClient = /** @class */function () {
  function OAuthClient(_a) {
    var clientId = _a.clientId,
      clientSecret = _a.clientSecret,
      baseUrl = _a.baseUrl,
      _b = _a.storage,
      storage = _b === void 0 ? sessionStorage : _b;
    this.listeners = {};
    this.authenticated = false;
    this.clientId = clientId;
    this.clientSecret = clientSecret;
    this.baseUrl = baseUrl;
    if (!storage) {
      throw new Error("Unable to store session");
    }
    this.storage = storage;
  }
  OAuthClient.prototype.hasAccessToken = function () {
    return null !== this.getAccessToken();
  };
  OAuthClient.prototype.getAccessToken = function () {
    return this.storage.getItem(accessTokenStorageKey);
  };
  OAuthClient.prototype.setAccessToken = function (accessToken) {
    return this.storage.setItem(accessTokenStorageKey, accessToken);
  };
  OAuthClient.prototype.setUsername = function (username) {
    return this.storage.setItem(usernameStorageKey, username);
  };
  OAuthClient.prototype.getUsername = function () {
    return this.storage.getItem(usernameStorageKey);
  };
  OAuthClient.prototype.isAuthenticated = function () {
    return this.authenticated;
  };
  OAuthClient.prototype.logout = function () {
    this.authenticated = false;
    this.storage.removeItem(accessTokenStorageKey);
    this.storage.removeItem(usernameStorageKey);
    this.triggerEvent(logoutEventType);
  };
  OAuthClient.prototype.registerListener = function (event, callback) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callback);
  };
  OAuthClient.prototype.unregisterListener = function (event, callback) {
    if (!this.listeners[event]) {
      return;
    }
    var index = this.listeners[event].findIndex(function (c) {
      return c === callback;
    });
    if (index >= 0) {
      delete this.listeners[event][index];
    }
  };
  OAuthClient.prototype.triggerEvent = function (type, event) {
    if (event === void 0) {
      event = {};
    }
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            event.type = type;
            if (!this.listeners[type]) {
              return [2 /*return*/, Promise.resolve()];
            }
            return [4 /*yield*/, Promise.all(this.listeners[type].map(function (func) {
              return func(event);
            }).filter(function (f) {
              return !!f;
            }))];
          case 1:
            _a.sent();
            return [2 /*return*/];
        }
      });
    });
  };

  OAuthClient.prototype.authenticate = function (authUrl) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var data, e_1, err;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            if (!this.hasAccessToken()) {
              throw new Error("Missing access token");
            }
            _b.label = 1;
          case 1:
            _b.trys.push([1, 4,, 5]);
            return [4 /*yield*/, axios.get(authUrl !== null && authUrl !== void 0 ? authUrl : "".concat(this.baseUrl, "/userinfo"), {
              headers: {
                authorization: "Bearer ".concat(this.getAccessToken())
              }
            })];
          case 2:
            data = _b.sent().data;
            this.authenticated = true;
            return [4 /*yield*/, this.triggerEvent(authenticationEventType, {
              user: data
            })];
          case 3:
            _b.sent();
            this.setUsername(data.username);
            return [2 /*return*/, data];
          case 4:
            e_1 = _b.sent();
            if (axios.isAxiosError(e_1)) {
              err = e_1;
              if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                this.logout();
              }
            }
            throw e_1;
          case 5:
            return [2 /*return*/];
        }
      });
    });
  };

  OAuthClient.prototype.getAccessTokenFromAuthCode = function (code, redirectUri) {
    return __awaiter(this, void 0, void 0, function () {
      var _a, clientId, clientSecret, baseUrl, data;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            _a = this, clientId = _a.clientId, clientSecret = _a.clientSecret, baseUrl = _a.baseUrl;
            return [4 /*yield*/, axios.post("".concat(baseUrl, "/oauth/v2/token"), {
              code: code,
              grant_type: 'authorization_code',
              client_id: clientId,
              client_secret: clientSecret,
              redirect_uri: redirectUri
            })];
          case 1:
            data = _b.sent().data;
            this.setAccessToken(data.access_token);
            return [4 /*yield*/, this.triggerEvent(loginEventType)];
          case 2:
            _b.sent();
            return [2 /*return*/, data];
        }
      });
    });
  };
  OAuthClient.prototype.login = function (username, password) {
    return __awaiter(this, void 0, void 0, function () {
      var res;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.doLogin(username, password)];
          case 1:
            res = _a.sent();
            return [4 /*yield*/, this.triggerEvent(loginEventType)];
          case 2:
            _a.sent();
            return [2 /*return*/, res];
        }
      });
    });
  };
  OAuthClient.prototype.doLogin = function (username, password) {
    return __awaiter(this, void 0, void 0, function () {
      var _a, clientId, clientSecret, baseUrl, data;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            _a = this, clientId = _a.clientId, clientSecret = _a.clientSecret, baseUrl = _a.baseUrl;
            return [4 /*yield*/, axios.post("".concat(baseUrl, "/oauth/v2/token"), {
              username: username,
              password: password,
              grant_type: 'password',
              client_id: clientId,
              client_secret: clientSecret
            })];
          case 1:
            data = _b.sent().data;
            this.setAccessToken(data.access_token);
            return [2 /*return*/, data];
        }
      });
    });
  };
  OAuthClient.prototype.createAuthorizeUrl = function (_a) {
    var _b = _a.redirectPath,
      redirectPath = _b === void 0 ? '/auth' : _b,
      connectTo = _a.connectTo,
      state = _a.state;
    var baseUrl = [window.location.protocol, '//', window.location.host].join('');
    var redirectUri = "".concat(redirectPath.indexOf('/') === 0 ? baseUrl : '').concat(redirectPath);
    var queryString = "response_type=code&client_id=".concat(encodeURIComponent(this.clientId), "&redirect_uri=").concat(encodeURIComponent(redirectUri)).concat(connectTo ? "&connect=".concat(encodeURIComponent(connectTo)) : '').concat(state ? "&state=".concat(encodeURIComponent(state)) : '');
    return "".concat(this.baseUrl, "/oauth/v2/auth?").concat(queryString);
  };
  return OAuthClient;
}();
export default OAuthClient;